'use strict';

angular.module('enervexSalesappApp').factory('User', function ($resource) {
	return $resource('/api/users/:id/:controller/:resendInvite', {
		id: '@_id'
	},
	{
		changePassword: {
			method: 'PUT',
			params: {
				controller:'password'
			}
		},
		get: {
			method: 'GET',
			params: {
				id:'me'
			}
		},
		update: {
			method: 'PUT'
		},
		checkLegacy: {
			method: "GET",
			isArray:true,
			params: {
				id: "check-legacy"
			}
		},
		resendInvite: {
			method: "POST",
			params: {
				resendInvite: 'resend-invite'
			}
		},
		resendPassword: {
			method: "POST",
			params: {
				resendInvite: 'resend-password'
			}
		}
	});
});
